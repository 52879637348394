
import { IonRefresher,  IonRefresherContent, IonLoading } from '@ionic/vue';
import {add,refresh} from 'ionicons/icons';
import BaseLayout from "@/components/BaseLayout.vue";
import ActivityCard from "@/components/card/ActivityCard.vue";
import MessageCard from "@/components/card/MessageCard.vue";
import ActivityServices from "@/services/activity.services";
import activity from "@/views/Activity.vue";

export default {
  name: 'FindActivity',
  components:{
    BaseLayout,
    ActivityCard,
    IonRefresher,
    IonRefresherContent,
    IonLoading,
    MessageCard

  },
  setup(){
    return {
      add,
      refresh
    }
  },
  computed:{
    hasActivities(){
      return this.activities.length > 0;
    }
  },
  data(){
    return{
      loading: false,
      activities: [],
      error: false,
      missingMsg:{
        title:'No Activities Found',
        content: 'Try joining a group to view available activities or start your own group and create new activities',
        linkEnabled: true,
        link: '/groups',
        button: 'Groups'
      }
    }
  },
  created(){
    this.loadActivities();
  },
  methods:{
    loadActivities(){
      this.loading =true;
       ActivityServices.findAvailableActivities().then(
          res =>{
            this.activities = res;
            this.loading = false;
            this.error= false;
          }, error => {
             console.log(error);
             this.error= true;
             this.loading = false;
          }
      )
    },
    refreshData($event){
      ActivityServices.findAvailableActivities().then(
          res =>{
            this.activities = res;
            this.loading = false;
            this.error= false;
            $event.target.complete();
          },
          err =>{
            console.log(err);
            this.error= true;
            this.loading = false;
          }
      )
    }
  },
  watcher:{
    activities(){
       return this.activities;
    }
  }
}

