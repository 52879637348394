import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, { class: "center-text" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.message.title), 1)
            ]),
            _: 1
          }),
          _withDirectives(_createVNode(_component_ion_card_subtitle, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.message.subtitle), 1)
            ]),
            _: 1
          }, 512), [
            [_vShow, $props.message.subtitle]
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_card_content, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($props.message.content) + " ", 1),
          ($props.message.linkEnabled)
            ? (_openBlock(), _createBlock(_component_ion_button, {
                key: 0,
                "router-link": $props.message.link,
                shape: "round",
                expand: "full"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($props.message.button), 1)
                ]),
                _: 1
              }, 8, ["router-link"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}