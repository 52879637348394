
import { IonCard, IonCardTitle, IonCardSubtitle, IonCardHeader, IonCardContent, IonButton  } from '@ionic/vue';

function initialMessage(){
  return{
    message:{
      linkEnabled: false,
      title:'',
      subtitle:'',
      link:'/activity/find'
    }
  }
}

export default  {
  name: 'MessageCard',
  props:{
    message:{
      type: Object,
      default: initialMessage()
    }
  },
  components: {
    IonCard,
    IonCardTitle,
    IonCardSubtitle,
    IonCardHeader,
    IonCardContent,
    IonButton,
  },
  computed:{

  },
  setup() {
    return {
    }
  }
}

